import "core-js/modules/es.array.push.js";
import "core-js/modules/es.set.difference.v2.js";
import "core-js/modules/es.set.intersection.v2.js";
import "core-js/modules/es.set.is-disjoint-from.v2.js";
import "core-js/modules/es.set.is-subset-of.v2.js";
import "core-js/modules/es.set.is-superset-of.v2.js";
import "core-js/modules/es.set.symmetric-difference.v2.js";
import "core-js/modules/es.set.union.v2.js";
import * as echarts from 'echarts';
import { xueQingActiveClassOptions, xueQingActiveDetail, xueQingActiveRank, xueQingScoreRange, xueQingUpline } from "@/api/api";
export default {
  name: "xqBoard",
  data() {
    return {
      from: 'garde',
      // class
      //
      active_id: null,
      info: null,
      silvData: [],
      // 四率
      // 排行
      classTop: [],
      studentTop: [],
      subjectTop: [],
      // 上线率
      upline_scores: [],
      uplineData: [],
      //
      classOptions: [],
      class_id: '',
      //分数段
      score_length: 10,
      score_left: 0,
      score_right: 750,
      stepOptions: [],
      rangeData: []
    };
  },
  mounted() {
    let {
      active_id
    } = this.$root.useRoute.params;
    let {
      from,
      class_id
    } = this.$root.useRoute.query;
    if (active_id > 0) {
      this.from = from || 'grade';
      this.active_id = active_id;
      this.class_id = Number(class_id) || '';
      if (this.class_id > 0 || this.from === 'class') {
        this.form = 'class';
        this.getClassOptions();
        return;
      }
      this.getInfo();
      this.getRank();
    } else {
      this.$root.useRouter.back();
    }
  },
  methods: {
    getClassOptions() {
      xueQingActiveClassOptions(this.active_id).then(res => {
        this.classOptions = (res === null || res === void 0 ? void 0 : res.data) || [];
        if (this.classOptions.length < 1) {
          this.$message.error('该考试没有班级');
          this.$root.useRouter.back();
          return;
        }
        if (!this.class_id) {
          this.class_id = this.classOptions[0].id || '';
        }
        this.getInfo();
        this.getRank();
      });
    },
    handleClassChange() {
      this.getInfo();
      this.getRank();
    },
    getInfo() {
      xueQingActiveDetail(this.active_id, this.class_id).then(res => {
        this.info = res.data || null;
        if (this.info) {
          // 上线率
          this.getUpline();
          // 分数段
          this.score_left = 0;
          this.score_right = this.info.total_score || 750;
          this.getRangeVal();
          this.getRange();
          // 四率数据
          this.silvData = [{
            value: Number(this.info.rate_excellent),
            count: this.info.rate_excellent_count,
            name: '优秀',
            range: '(90%-100%)',
            itemStyle: {
              color: '#3C9CFF'
            }
          }, {
            value: Number(this.info.rate_good),
            count: this.info.rate_good_count,
            name: '良好',
            range: '(80%-90%)',
            itemStyle: {
              color: '#F9AE3D'
            }
          }, {
            value: Number(this.info.rate_fair),
            count: this.info.rate_fair_count,
            name: '及格',
            range: '(60%-80%)',
            itemStyle: {
              color: '#5AC725'
            }
          }, {
            value: Number(this.info.rete_poor),
            count: this.info.rete_poor_count,
            name: '低分',
            range: '(0%-60%)',
            itemStyle: {
              color: '#F56C6C'
            }
          }];
          this.initPieChart(this.silvData);
        }
      });
    },
    //
    getUpline() {
      // 去重
      this.upline_scores = Array.from(new Set(this.upline_scores));
      xueQingUpline(this.active_id, {
        upline_scores: this.upline_scores,
        class_id: this.class_id
      }).then(res => {
        if (!res.data) return;
        this.upline_scores = res.data.scores || [];
        this.uplineData = res.data.list || [];
      }).catch(err => {
        console.log('获取上线率失败', err);
      });
    },
    handleUplineChange() {
      for (let [key, item] of this.upline_scores.entries()) {
        if (Number(item) > this.info.total_score) {
          this.$message.error(`分数线${1 + key}不能大于满分：${this.info.total_score}`);
          return;
        }
      }
      this.getUpline();
    },
    handleAddUpline() {
      if (this.upline_scores.length >= 4) return;
      for (let item of this.upline_scores) {
        if (String(item).trim().length < 1) {
          this.$message.error('请完善分数线后再添加');
          return;
        }
      }
      this.upline_scores.push('');
    },
    handleDeleteUpline() {
      if (this.upline_scores.length <= 1) return;
      this.upline_scores.pop();
      this.getUpline();
    },
    //
    getRange() {
      if (isNaN(Number(this.score_left)) || isNaN(Number(this.score_right)) || Number(this.score_left) >= Number(this.score_right)) {
        this.$message.error('起止分数错误');
        return;
      }
      xueQingScoreRange(this.active_id, {
        score_left: this.score_left || 0,
        score_right: this.score_right,
        score_length: this.score_length,
        class_id: this.class_id
      }).then(res => {
        if (!res.data) return;
        this.rangeData = res.data.list;
      });
    },
    handleLRChange() {
      if (isNaN(Number(this.score_left)) || isNaN(Number(this.score_right)) || Number(this.score_left) >= Number(this.score_right)) {
        this.$message.error('起止分数错误');
        return;
      }
      this.getRangeVal();
      this.getRange();
    },
    getRangeVal() {
      if (this.score_right - this.score_left >= 400) {
        this.stepOptions = [100, 150, 200];
      } else if (this.score_right - this.score_left >= 300) {
        this.stepOptions = [50, 100, 150, 200];
      } else if (this.score_right - this.score_left >= 150) {
        this.stepOptions = [40, 60, 80, 100];
      } else if (this.score_right - this.score_left >= 100) {
        this.stepOptions = [20, 50, 100];
      } else {
        this.stepOptions = [10, 20, 30, 40, 50];
      }
      this.score_length = this.stepOptions[0];
    },
    //
    getRank() {
      xueQingActiveRank(this.active_id, this.class_id).then(res => {
        var _res$data, _res$data2, _res$data3;
        this.classTop = (res === null || res === void 0 || (_res$data = res.data) === null || _res$data === void 0 ? void 0 : _res$data.class_top) || [];
        this.studentTop = (res === null || res === void 0 || (_res$data2 = res.data) === null || _res$data2 === void 0 ? void 0 : _res$data2.student_top) || [];
        this.subjectTop = (res === null || res === void 0 || (_res$data3 = res.data) === null || _res$data3 === void 0 ? void 0 : _res$data3.subject_top) || [];
      });
    },
    initPieChart(data) {
      let chartDom = document.getElementById('silvChart');
      let myChart = echarts.init(chartDom);
      myChart.clear();
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: name => {
            return `<div style="opacity: 0.95;font-size: 14px;" >
                               <div style="font-weight: bolder;padding-bottom: 5px;">${name.seriesName}</div>
                               <div >${name.name} 占比${name.percent}%</div>
                       </div>`;
          }
        },
        grid: {
          left: 10,
          top: 10,
          right: 10,
          bottom: 10
        },
        series: [{
          name: '四率',
          type: 'pie',
          radius: ['0%', '90%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 2,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false
          },
          labelLine: {
            show: false
          },
          data: data
        }]
      };
      myChart.setOption(option);
    },
    TabChange(key) {
      if (key === 'subject') {
        this.$root.useRouter.push(`/xueqing/xqStat/${this.active_id}?class_id=${this.class_id}`);
      }
      if (key === 'question') {
        this.$root.useRouter.push(`/xueqing/xqQuestions/${this.active_id}?class_id=${this.class_id}`);
      }
    },
    // 查看折线图
    toLineChart(type) {
      this.$root.useRouter.push(`/xueqing/xqLineChart/${this.active_id}?type=${type}&class_id=${this.class_id}&name=${this.info.active_name}&total=${this.info.total_score}`);
    }
  }
};